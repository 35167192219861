import { buildUrlString } from '~/utils/buildUrlString';

export function buildPdpUrl(
  locale: string,
  productUrlKey: string,
  withAbsolutePath: boolean | string = false
) {
  return buildUrlString(
    locale,
    `/shop/${productUrlKey}/`,
    undefined,
    undefined,
    withAbsolutePath
  );
}
