import type { Ref } from 'vue';
import { z } from 'zod';
import { isAlgoliaDocument } from '~/utils/guards/isAlgoliaDocument';
import type { GlobalSearchResponseBody } from '~/server/api/algolia/search.post';
import { extractError } from '~/utils/extractError';

export const configurationSchema = z.object({
  limit: z.number().default(9),
  sortOrder: z
    .object({
      products: z.number().default(1),
      contentPages: z.number().default(2),
      documents: z.number().default(3)
    })
    .default({
      products: 1,
      contentPages: 2,
      documents: 3
    })
});

function createCacheKey(query: string, type: string | null) {
  return `${query}-${type}`;
}

export type UseGlobalSearchOptions = Partial<
  z.infer<typeof configurationSchema>
>;

export function useGlobalSearch(configurationRef: Ref<UseGlobalSearchOptions>) {
  const logger = useLogger();
  const locale = useLocale();
  const router = useRouter();
  const route = useRoute();
  const configuration = computed(() =>
    configurationSchema.parse(configurationRef ?? {})
  );

  const responseCache = ref<Record<string, GlobalSearchResponseBody>>({});
  const loading = ref(false);
  const loadingMore = ref(false);
  const error = ref<string | null>(null);
  const searchResponse = ref<GlobalSearchResponseBody | null>(null);
  const searchQuery = ref(route.query.q?.toString() ?? '');
  const searchInputValue = ref(searchQuery.value);
  const activeType = ref<string | null>(route.query.type?.toString() ?? null);
  const abortControllerRef = ref<AbortController | null>(null);

  const hasResults = computed(
    () =>
      searchResponse.value != null && searchResponse.value.results.length > 0
  );
  const hasProductResults = computed(
    () => searchResponse.value?.hasProductResults ?? false
  );
  const hasDocumentResults = computed(
    () => searchResponse.value?.hasDocumentResults ?? false
  );
  const currentResults = computed(() => searchResponse.value?.results ?? []);
  const hasMoreResults = computed(
    () => searchResponse.value?.hasMoreResults ?? false
  );

  function handleSubmit() {
    router.push({
      query: {
        q: searchInputValue.value
      }
    });
  }

  async function fetchResults(query: string, type: string | null) {
    const trimmedQuery = query.trim();
    if (!trimmedQuery) {
      return;
    }

    const cacheKey = createCacheKey(trimmedQuery, type);
    if (responseCache.value[cacheKey]) {
      searchResponse.value = responseCache.value[cacheKey];

      return;
    }

    loading.value = true;

    if (!abortControllerRef.value?.signal.aborted) {
      abortControllerRef.value?.abort();
    }
    const abortController = new AbortController();
    abortControllerRef.value = abortController;

    try {
      searchResponse.value = await $fetch<GlobalSearchResponseBody>(
        '/api/algolia/search',
        {
          ...DEFAULT_FETCH_OPTIONS,
          method: 'POST',
          body: {
            query: trimmedQuery,
            type,
            hitsPerPage: configuration.value.limit,
            locale: locale.value
          },
          signal: abortController.signal
        }
      );
      responseCache.value[cacheKey] = searchResponse.value;
      error.value = null;
    } catch (e) {
      if (!abortController.signal.aborted) {
        error.value = extractError(e) ?? 'Error while fetching search results.';
      }
    } finally {
      loading.value = false;
    }

    const firstResult = searchResponse.value?.results[0];
    // open document in current tab
    const shouldOpenDocument =
      searchResponse.value?.results.length === 1 &&
      isAlgoliaDocument(firstResult);

    if (shouldOpenDocument) {
      const link = buildDocumentDownloadLink(firstResult, locale.value);
      if (link) {
        window.location.href = link;
      }
    }
  }

  async function fetchMoreResults() {
    const cacheKey = createCacheKey(searchQuery.value, activeType.value);
    const cachedResponse = responseCache.value[cacheKey];
    if (!cachedResponse) {
      logger.warn(
        'useGlobalSearch',
        'no response cached - can not load more results'
      );

      return;
    }

    loadingMore.value = true;
    try {
      const response = await $fetch<GlobalSearchResponseBody>(
        '/api/algolia/search',
        {
          ...DEFAULT_FETCH_OPTIONS,
          method: 'POST',
          body: {
            query: searchQuery.value,
            type: activeType.value,
            hitsPerPage: cachedResponse.hitsPerPage,
            offset: cachedResponse.nextOffset,
            sortOrder: configuration.value.sortOrder,
            locale: locale.value
          }
        }
      );
      cachedResponse.nextOffset = response.nextOffset;
      cachedResponse.hasMoreResults = response.hasMoreResults;
      // add results to existing response
      cachedResponse.results.push(...response.results);

      error.value = null;
    } catch (e) {
      error.value = extractError(e) ?? 'Error while fetching search results.';
    } finally {
      loadingMore.value = false;
    }
  }

  function handleContentTagChange(type: string) {
    const query = {
      ...route.query
    };

    if (activeType.value === type) {
      delete query.type;
    } else {
      query.type = type;
    }

    return router.push({
      query
    });
  }

  function handleLoadMore() {
    if (loadingMore.value) {
      return;
    }

    return fetchMoreResults();
  }

  watch(searchQuery, (newValue) => {
    if (searchInputValue.value !== newValue) {
      searchInputValue.value = newValue;
    }
  });

  return {
    handleSubmit,
    handleLoadMore,
    handleContentTagChange,
    fetchResults,
    searchQuery,
    searchInputValue,
    searchResponse,
    activeType,
    currentResults,
    hasMoreResults,
    hasProductResults,
    hasDocumentResults,
    hasResults,
    loading,
    loadingMore,
    error
  };
}
