<template>
  <NuxtErrorBoundary @error="handleError">
    <slot />
    <template v-if="isPreviewEnabled" #error="{ error }">
      <!-- error is a ref but seems to be not automatically de-reffed here -->
      <template v-if="!error.value.fatal">
        <HeadlineBlock
          :content="'Error rendering component ' + (componentName ?? '')"
          :cols="12"
          :level="3"
        />
        <Card>
          <pre>
Error:
{{ error }}
        </pre
          >
          <pre v-if="error.cause">
Cause:
{{ error }}
        </pre
          >
          <pre>
Child Props:
{{ childProps }}
        </pre
          >
        </Card>
      </template>
    </template>
  </NuxtErrorBoundary>
</template>

<script setup lang="ts">
import { Card, HeadlineBlock } from '@hypercodestudio/basler-components';

interface Props {
  componentName?: string;
  childProps?: unknown;
}

defineProps<Props>();

const {
  public: { isPreviewEnabled }
} = useRuntimeConfig();
const logger = useLogger();

function handleError(error: unknown) {
  logger.error('ErrorBoundary', error);
  // NuxtErrorBoundary catches all errors - including fatal errors.
  // A fatal error prevent navigation until it is cleared - therefore we
  // show a full screen error on fatal errors.
  if (error instanceof Error && 'fatal' in error && error.fatal) {
    showError(error);
  }
}
</script>
