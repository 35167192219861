import type { ResolvedGlobalPageSettings } from '~/lib/ContentfulGraphqlService';

export default defineNuxtPlugin({
  name: 'globalPageSettings',
  enforce: 'pre',
  async setup() {
    const { $locale } = useNuxtApp();
    const globalPageSettings = useState<ResolvedGlobalPageSettings | null>(
      'globalPageSettings'
    );

    if (!globalPageSettings.value) {
      globalPageSettings.value = (
        await useGlobalPageSettings($locale.value)
      ).value;
    }

    return {
      provide: {
        globalPageSettings
      }
    };
  }
});
