export const countries = [
  {
    countryNameLocal: 'Andorra',
    countryCode: 'AD',
    currencyCode: 'EUR',
    officialLanguageCode: 'ca'
  },
  {
    countryNameLocal:
      'د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان',
    countryCode: 'AF',
    currencyCode: 'AFN',
    officialLanguageCode: 'fa'
  },
  {
    countryNameLocal: 'Antigua and Barbuda',
    countryCode: 'AG',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Anguilla',
    countryCode: 'AI',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Shqipëria',
    countryCode: 'AL',
    currencyCode: 'ALL',
    officialLanguageCode: 'sq'
  },
  {
    countryNameLocal: 'Հայաստան',
    countryCode: 'AM',
    currencyCode: 'AMD',
    officialLanguageCode: 'hy'
  },
  {
    countryNameLocal: 'Angola',
    countryCode: 'AO',
    currencyCode: 'AOA',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Antarctica, Antártico, Antarctique, Антарктике',
    countryCode: 'AQ',
    currencyCode: '',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Argentina',
    countryCode: 'AR',
    currencyCode: 'ARS',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'American Samoa',
    countryCode: 'AS',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Österreich',
    countryCode: 'AT',
    currencyCode: 'EUR',
    officialLanguageCode: 'de'
  },
  {
    countryNameLocal: 'Australia',
    countryCode: 'AU',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Aruba',
    countryCode: 'AW',
    currencyCode: 'AWG',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'Åland',
    countryCode: 'AX',
    currencyCode: 'EUR',
    officialLanguageCode: 'sv'
  },
  {
    countryNameLocal: 'Azərbaycan',
    countryCode: 'AZ',
    currencyCode: 'AZN',
    officialLanguageCode: 'az'
  },
  {
    countryNameLocal: 'Bosna i Hercegovina',
    countryCode: 'BA',
    currencyCode: 'BAM',
    officialLanguageCode: 'bs'
  },
  {
    countryNameLocal: 'Barbados',
    countryCode: 'BB',
    currencyCode: 'BBD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'গণপ্রজাতন্ত্রী বাংলাদেশ',
    countryCode: 'BD',
    currencyCode: 'BDT',
    officialLanguageCode: 'bn'
  },
  {
    countryNameLocal: 'België, Belgique, Belgien',
    countryCode: 'BE',
    currencyCode: 'EUR',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'Burkina Faso',
    countryCode: 'BF',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'България',
    countryCode: 'BG',
    currencyCode: 'BGN',
    officialLanguageCode: 'bg'
  },
  {
    countryNameLocal: 'البحرين',
    countryCode: 'BH',
    currencyCode: 'BHD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Burundi',
    countryCode: 'BI',
    currencyCode: 'BIF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Bénin',
    countryCode: 'BJ',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Saint-Barthélemy',
    countryCode: 'BL',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Bermuda',
    countryCode: 'BM',
    currencyCode: 'BMD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Brunei Darussalam',
    countryCode: 'BN',
    currencyCode: 'BND',
    officialLanguageCode: 'ms'
  },
  {
    countryNameLocal: 'Bolivia, Bulibiya, Volívia, Wuliwya',
    countryCode: 'BO',
    currencyCode: 'BOP',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Caribisch Nederland',
    countryCode: 'BQ',
    currencyCode: 'USD',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'Brasil',
    countryCode: 'BR',
    currencyCode: 'BRL',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'འབྲུག་ཡུལ',
    countryCode: 'BT',
    currencyCode: 'BTN',
    officialLanguageCode: 'dz'
  },
  {
    countryNameLocal: 'Bouvetøya',
    countryCode: 'BV',
    currencyCode: 'NOK',
    officialLanguageCode: 'no'
  },
  {
    countryNameLocal: 'Botswana',
    countryCode: 'BW',
    currencyCode: 'BWP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Беларусь',
    countryCode: 'BY',
    currencyCode: 'BYR',
    officialLanguageCode: 'be'
  },
  {
    countryNameLocal: 'Belize',
    countryCode: 'BZ',
    currencyCode: 'BZD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Canada',
    countryCode: 'CA',
    currencyCode: 'CAD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Schweiz, Suisse, Svizzera, Svizra',
    countryCode: 'CH',
    currencyCode: 'CHE',
    officialLanguageCode: 'de'
  },
  {
    countryNameLocal: "Côte d'Ivoire",
    countryCode: 'CI',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Chile',
    countryCode: 'CL',
    currencyCode: 'CLP',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Cameroun, Cameroon',
    countryCode: 'CM',
    currencyCode: 'XAF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: '中国',
    countryCode: 'CN',
    currencyCode: 'CNY',
    officialLanguageCode: 'zh-hans'
  },
  {
    countryNameLocal: 'Colombia',
    countryCode: 'CO',
    currencyCode: 'COP',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Costa Rica',
    countryCode: 'CR',
    currencyCode: 'CRC',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Cuba',
    countryCode: 'CU',
    currencyCode: 'CUC',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Cabo Verde',
    countryCode: 'CV',
    currencyCode: 'CVE',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Curaçao',
    countryCode: 'CW',
    currencyCode: 'ANG',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'Christmas Island',
    countryCode: 'CX',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Κύπρος, Kibris',
    countryCode: 'CY',
    currencyCode: 'EUR',
    officialLanguageCode: 'el'
  },
  {
    countryNameLocal: 'Deutschland',
    countryCode: 'DE',
    currencyCode: 'EUR',
    officialLanguageCode: 'de'
  },
  {
    countryNameLocal: 'Djibouti, جيبوتي, Jabuuti, Gabuutih',
    countryCode: 'DJ',
    currencyCode: 'DJF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Danmark',
    countryCode: 'DK',
    currencyCode: 'DKK',
    officialLanguageCode: 'da'
  },
  {
    countryNameLocal: 'Dominica',
    countryCode: 'DM',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'الجزائر',
    countryCode: 'DZ',
    currencyCode: 'DZD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Ecuador',
    countryCode: 'EC',
    currencyCode: 'USD',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Eesti',
    countryCode: 'EE',
    currencyCode: 'EUR',
    officialLanguageCode: 'et'
  },
  {
    countryNameLocal: 'مصر',
    countryCode: 'EG',
    currencyCode: 'EGP',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Sahara Occidental',
    countryCode: 'EH',
    currencyCode: 'MAD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'ኤርትራ, إرتريا, Eritrea',
    countryCode: 'ER',
    currencyCode: 'ERN',
    officialLanguageCode: 'ti'
  },
  {
    countryNameLocal: 'España',
    countryCode: 'ES',
    currencyCode: 'EUR',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'ኢትዮጵያ, Itoophiyaa',
    countryCode: 'ET',
    currencyCode: 'ETB',
    officialLanguageCode: 'am'
  },
  {
    countryNameLocal: 'Suomi',
    countryCode: 'FI',
    currencyCode: 'EUR',
    officialLanguageCode: 'fi'
  },
  {
    countryNameLocal: 'Fiji',
    countryCode: 'FJ',
    currencyCode: 'FJD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Micronesia',
    countryCode: 'FM',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'France',
    countryCode: 'FR',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Gabon',
    countryCode: 'GA',
    currencyCode: 'XAF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Grenada',
    countryCode: 'GD',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'საქართველო',
    countryCode: 'GE',
    currencyCode: 'GEL',
    officialLanguageCode: 'ka'
  },
  {
    countryNameLocal: 'Guyane française',
    countryCode: 'GF',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Guernsey',
    countryCode: 'GG',
    currencyCode: 'GBP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Ghana',
    countryCode: 'GH',
    currencyCode: 'GHS',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Gibraltar',
    countryCode: 'GI',
    currencyCode: 'GIP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Kalaallit Nunaat, Grønland',
    countryCode: 'GL',
    currencyCode: 'DKK',
    officialLanguageCode: 'kl'
  },
  {
    countryNameLocal: 'Guinée',
    countryCode: 'GN',
    currencyCode: 'GNF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Guadeloupe',
    countryCode: 'GP',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial',
    countryCode: 'GQ',
    currencyCode: 'XAF',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Ελλάδα',
    countryCode: 'GR',
    currencyCode: 'EUR',
    officialLanguageCode: 'el'
  },
  {
    countryNameLocal: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    currencyCode: '',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Guatemala',
    countryCode: 'GT',
    currencyCode: 'GTQ',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Guam, Guåhån',
    countryCode: 'GU',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Guiné-Bissau',
    countryCode: 'GW',
    currencyCode: 'XOF',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Guyana',
    countryCode: 'GY',
    currencyCode: 'GYD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: '香港, Hong Kong',
    countryCode: 'HK',
    currencyCode: 'HKD',
    officialLanguageCode: 'zh-hant'
  },
  {
    countryNameLocal: 'Honduras',
    countryCode: 'HN',
    currencyCode: 'HNL',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Hrvatska',
    countryCode: 'HR',
    currencyCode: 'HRK',
    officialLanguageCode: 'hr'
  },
  {
    countryNameLocal: 'Haïti, Ayiti',
    countryCode: 'HT',
    currencyCode: 'HTG',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Magyarország',
    countryCode: 'HU',
    currencyCode: 'HUF',
    officialLanguageCode: 'hu'
  },
  {
    countryNameLocal: 'Indonesia',
    countryCode: 'ID',
    currencyCode: 'IDR',
    officialLanguageCode: 'id'
  },
  {
    countryNameLocal: 'Ireland, Éire',
    countryCode: 'IE',
    currencyCode: 'EUR',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'ישראל',
    countryCode: 'IL',
    currencyCode: 'ILS',
    officialLanguageCode: 'he'
  },
  {
    countryNameLocal: 'Isle of Man',
    countryCode: 'IM',
    currencyCode: 'GBP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'भारत, India',
    countryCode: 'IN',
    currencyCode: 'INR',
    officialLanguageCode: 'hi'
  },
  {
    countryNameLocal: 'British Indian Ocean Territories',
    countryCode: 'IO',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'العراق, Iraq',
    countryCode: 'IQ',
    currencyCode: 'IQD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'ایران',
    countryCode: 'IR',
    currencyCode: 'IRR',
    officialLanguageCode: 'fa'
  },
  {
    countryNameLocal: 'Ísland',
    countryCode: 'IS',
    currencyCode: 'ISK',
    officialLanguageCode: 'is'
  },
  {
    countryNameLocal: 'Italia',
    countryCode: 'IT',
    currencyCode: 'EUR',
    officialLanguageCode: 'it'
  },
  {
    countryNameLocal: 'Jersey',
    countryCode: 'JE',
    currencyCode: 'GBP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Jamaica',
    countryCode: 'JM',
    currencyCode: 'JMD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'الأُرْدُن',
    countryCode: 'JO',
    currencyCode: 'JOD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: '日本',
    countryCode: 'JP',
    currencyCode: 'JPY',
    officialLanguageCode: 'ja'
  },
  {
    countryNameLocal: 'Kenya',
    countryCode: 'KE',
    currencyCode: 'KES',
    officialLanguageCode: 'sw'
  },
  {
    countryNameLocal: 'Кыргызстан, Киргизия',
    countryCode: 'KG',
    currencyCode: 'KGS',
    officialLanguageCode: 'ky'
  },
  {
    countryNameLocal: 'កម្ពុជា',
    countryCode: 'KH',
    currencyCode: 'KHR',
    officialLanguageCode: 'km'
  },
  {
    countryNameLocal: '대한민국',
    countryCode: 'KR',
    currencyCode: 'KRW',
    officialLanguageCode: 'ko'
  },
  {
    countryNameLocal: 'Kiribati',
    countryCode: 'KI',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'الكويت',
    countryCode: 'KW',
    currencyCode: 'KWD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Қазақстан, Казахстан',
    countryCode: 'KZ',
    currencyCode: 'KZT',
    officialLanguageCode: 'kk'
  },
  {
    countryNameLocal: 'لبنان, Liban',
    countryCode: 'LB',
    currencyCode: 'LBP',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Saint Lucia',
    countryCode: 'LC',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Liechtenstein',
    countryCode: 'LI',
    currencyCode: 'CHF',
    officialLanguageCode: 'de'
  },
  {
    countryNameLocal: 'ශ්‍රී ලංකා, இலங்கை',
    countryCode: 'LK',
    currencyCode: 'LKR',
    officialLanguageCode: 'si'
  },
  {
    countryNameLocal: 'Liberia',
    countryCode: 'LR',
    currencyCode: 'LRD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Lesotho',
    countryCode: 'LS',
    currencyCode: 'LSL',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Lietuva',
    countryCode: 'LT',
    currencyCode: 'EUR',
    officialLanguageCode: 'lt'
  },
  {
    countryNameLocal: 'Lëtzebuerg, Luxembourg, Luxemburg',
    countryCode: 'LU',
    currencyCode: 'EUR',
    officialLanguageCode: 'lb'
  },
  {
    countryNameLocal: 'Latvija',
    countryCode: 'LV',
    currencyCode: 'EUR',
    officialLanguageCode: 'lv'
  },
  {
    countryNameLocal: 'ليبيا',
    countryCode: 'LY',
    currencyCode: 'LYD',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب',
    countryCode: 'MA',
    currencyCode: 'MAD',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Monaco',
    countryCode: 'MC',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Crna Gora, Црна Гора',
    countryCode: 'ME',
    currencyCode: 'EUR',
    officialLanguageCode: 'srp'
  },
  {
    countryNameLocal: 'Saint-Martin',
    countryCode: 'MF',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Madagasikara, Madagascar',
    countryCode: 'MG',
    currencyCode: 'MGA',
    officialLanguageCode: 'mg'
  },
  {
    countryNameLocal: 'Mali',
    countryCode: 'ML',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'မြန်မာ',
    countryCode: 'MM',
    currencyCode: 'MMK',
    officialLanguageCode: 'my'
  },
  {
    countryNameLocal: 'Монгол Улс',
    countryCode: 'MN',
    currencyCode: 'MNT',
    officialLanguageCode: 'mn'
  },
  {
    countryNameLocal: '澳門, Macau',
    countryCode: 'MO',
    currencyCode: 'MOP',
    officialLanguageCode: 'zh-hant'
  },
  {
    countryNameLocal: 'Martinique',
    countryCode: 'MQ',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'موريتانيا, Mauritanie',
    countryCode: 'MR',
    currencyCode: 'MRU',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Montserrat',
    countryCode: 'MS',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Malta',
    countryCode: 'MT',
    currencyCode: 'EUR',
    officialLanguageCode: 'mt'
  },
  {
    countryNameLocal: 'Maurice, Mauritius',
    countryCode: 'MU',
    currencyCode: 'MUR',
    officialLanguageCode: 'mfe'
  },
  {
    countryNameLocal: '',
    countryCode: 'MV',
    currencyCode: 'MVR',
    officialLanguageCode: 'dv'
  },
  {
    countryNameLocal: 'Malawi',
    countryCode: 'MW',
    currencyCode: 'MWK',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'México',
    countryCode: 'MX',
    currencyCode: 'MXN',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: '',
    countryCode: 'MY',
    currencyCode: 'MYR',
    officialLanguageCode: 'ms'
  },
  {
    countryNameLocal: 'Mozambique',
    countryCode: 'MZ',
    currencyCode: 'MZN',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Namibia',
    countryCode: 'NA',
    currencyCode: 'NAD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Nouvelle-Calédonie',
    countryCode: 'NC',
    currencyCode: 'XPF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Norfolk Island',
    countryCode: 'NF',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Nigeria',
    countryCode: 'NG',
    currencyCode: 'NGN',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Nicaragua',
    countryCode: 'NI',
    currencyCode: 'NIO',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Norge, Noreg',
    countryCode: 'NO',
    currencyCode: 'NOK',
    officialLanguageCode: 'nb'
  },
  {
    countryNameLocal: '',
    countryCode: 'NP',
    currencyCode: 'NPR',
    officialLanguageCode: 'ne'
  },
  {
    countryNameLocal: 'Nauru',
    countryCode: 'NR',
    currencyCode: 'AUD',
    officialLanguageCode: 'na'
  },
  {
    countryNameLocal: 'Niue',
    countryCode: 'NU',
    currencyCode: 'NZD',
    officialLanguageCode: 'niu'
  },
  {
    countryNameLocal: 'New Zealand',
    countryCode: 'NZ',
    currencyCode: 'NZD',
    officialLanguageCode: 'mi'
  },
  {
    countryNameLocal: 'سلطنة عُمان',
    countryCode: 'OM',
    currencyCode: 'OMR',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Panama',
    countryCode: 'PA',
    currencyCode: 'PAB',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Perú',
    countryCode: 'PE',
    currencyCode: 'PEN',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Polynésie française',
    countryCode: 'PF',
    currencyCode: 'XPF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Papua New Guinea',
    countryCode: 'PG',
    currencyCode: 'PGK',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'پاکستان',
    countryCode: 'PK',
    currencyCode: 'PKR',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Polska',
    countryCode: 'PL',
    currencyCode: 'PLN',
    officialLanguageCode: 'pl'
  },
  {
    countryNameLocal: 'Saint-Pierre-et-Miquelon',
    countryCode: 'PM',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Pitcairn',
    countryCode: 'PN',
    currencyCode: 'NZD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Puerto Rico',
    countryCode: 'PR',
    currencyCode: 'USD',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Palestinian Territory',
    countryCode: 'PS',
    currencyCode: '',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Portugal',
    countryCode: 'PT',
    currencyCode: 'EUR',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Palau',
    countryCode: 'PW',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Paraguay',
    countryCode: 'PY',
    currencyCode: 'PYG',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'قطر',
    countryCode: 'QA',
    currencyCode: 'QAR',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'La Réunion',
    countryCode: 'RE',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'România',
    countryCode: 'RO',
    currencyCode: 'RON',
    officialLanguageCode: 'ro'
  },
  {
    countryNameLocal: 'Србија',
    countryCode: 'RS',
    currencyCode: 'RSD',
    officialLanguageCode: 'sr'
  },
  {
    countryNameLocal: 'Россия',
    countryCode: 'RU',
    currencyCode: 'RUB',
    officialLanguageCode: 'ru'
  },
  {
    countryNameLocal: 'Rwanda',
    countryCode: 'RW',
    currencyCode: 'RWF',
    officialLanguageCode: 'rw'
  },
  {
    countryNameLocal: 'السعودية',
    countryCode: 'SA',
    currencyCode: 'SAR',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Solomon Islands',
    countryCode: 'SB',
    currencyCode: 'SBD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Seychelles',
    countryCode: 'SC',
    currencyCode: 'SCR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Sverige',
    countryCode: 'SE',
    currencyCode: 'SEK',
    officialLanguageCode: 'sv'
  },
  {
    countryNameLocal: 'Singapore',
    countryCode: 'SG',
    currencyCode: 'SGD',
    officialLanguageCode: 'zh-hans'
  },
  {
    countryNameLocal: 'Saint Helena',
    countryCode: 'SH',
    currencyCode: 'SHP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Slovenija',
    countryCode: 'SI',
    currencyCode: 'EUR',
    officialLanguageCode: 'sl'
  },
  {
    countryNameLocal: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    currencyCode: 'NOK',
    officialLanguageCode: 'no'
  },
  {
    countryNameLocal: 'Slovensko',
    countryCode: 'SK',
    currencyCode: 'EUR',
    officialLanguageCode: 'sk'
  },
  {
    countryNameLocal: 'Sierra Leone',
    countryCode: 'SL',
    currencyCode: 'SLL',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Repubblica di San Marino',
    countryCode: 'SM',
    currencyCode: 'EUR',
    officialLanguageCode: 'it'
  },
  {
    countryNameLocal: 'Sénégal',
    countryCode: 'SN',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Somalia, الصومال',
    countryCode: 'SO',
    currencyCode: 'SOS',
    officialLanguageCode: 'so'
  },
  {
    countryNameLocal: 'Suriname',
    countryCode: 'SR',
    currencyCode: 'SRD',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'South Sudan',
    countryCode: 'SS',
    currencyCode: 'SSP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'São Tomé e Príncipe',
    countryCode: 'ST',
    currencyCode: 'STN',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'El Salvador',
    countryCode: 'SV',
    currencyCode: 'SVC',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'Sint Maarten',
    countryCode: 'SX',
    currencyCode: 'ANG',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'سوريا, Sūriyya',
    countryCode: 'SY',
    currencyCode: 'SYP',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Tchad, تشاد',
    countryCode: 'TD',
    currencyCode: 'XAF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Togo',
    countryCode: 'TG',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'ประเทศไทย',
    countryCode: 'TH',
    currencyCode: 'THB',
    officialLanguageCode: 'th'
  },
  {
    countryNameLocal: ',',
    countryCode: 'TJ',
    currencyCode: 'TJS',
    officialLanguageCode: 'tg'
  },
  {
    countryNameLocal: 'Tokelau',
    countryCode: 'TK',
    currencyCode: 'NZD',
    officialLanguageCode: 'tkl'
  },
  {
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    countryCode: 'TL',
    currencyCode: 'USD',
    officialLanguageCode: 'pt'
  },
  {
    countryNameLocal: 'Türkmenistan',
    countryCode: 'TM',
    currencyCode: 'TMT',
    officialLanguageCode: 'tk'
  },
  {
    countryNameLocal: 'تونس, Tunisie',
    countryCode: 'TN',
    currencyCode: 'TND',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Tonga',
    countryCode: 'TO',
    currencyCode: 'TOP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Türkiye',
    countryCode: 'TR',
    currencyCode: 'TRY',
    officialLanguageCode: 'tr'
  },
  {
    countryNameLocal: 'Trinidad and Tobago',
    countryCode: 'TT',
    currencyCode: 'TTD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Tuvalu',
    countryCode: 'TV',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Tanzania',
    countryCode: 'TZ',
    currencyCode: 'TZS',
    officialLanguageCode: 'sw'
  },
  {
    countryNameLocal: 'Україна',
    countryCode: 'UA',
    currencyCode: 'UAH',
    officialLanguageCode: 'uk'
  },
  {
    countryNameLocal: 'Uganda',
    countryCode: 'UG',
    currencyCode: 'UGX',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'United States of America',
    countryCode: 'US',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Uruguay',
    countryCode: 'UY',
    currencyCode: 'UYU',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: '',
    countryCode: 'UZ',
    currencyCode: 'UZS',
    officialLanguageCode: 'uz'
  },
  {
    countryNameLocal: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    currencyCode: 'XCD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Venezuela',
    countryCode: 'VE',
    currencyCode: 'VES',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'British Virgin Islands',
    countryCode: 'VG',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'United States Virgin Islands',
    countryCode: 'VI',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Việt Nam',
    countryCode: 'VN',
    currencyCode: 'VND',
    officialLanguageCode: 'vi'
  },
  {
    countryNameLocal: 'Vanuatu',
    countryCode: 'VU',
    currencyCode: 'VUV',
    officialLanguageCode: 'bi'
  },
  {
    countryNameLocal: 'Wallis-et-Futuna',
    countryCode: 'WF',
    currencyCode: 'XPF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Samoa',
    countryCode: 'WS',
    currencyCode: 'WST',
    officialLanguageCode: 'sm'
  },
  {
    countryNameLocal: 'اليَمَن',
    countryCode: 'YE',
    currencyCode: 'YER',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Mayotte',
    countryCode: 'YT',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'South Africa',
    countryCode: 'ZA',
    currencyCode: 'ZAR',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Zambia',
    countryCode: 'ZM',
    currencyCode: 'ZMW',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Zimbabwe',
    countryCode: 'ZW',
    currencyCode: 'ZWL',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Swaziland',
    countryCode: 'SZ',
    currencyCode: 'SZL',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Македонија',
    countryCode: 'MK',
    currencyCode: 'MKD',
    officialLanguageCode: 'mk'
  },
  {
    countryNameLocal: 'Philippines',
    countryCode: 'PH',
    currencyCode: 'PHP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Nederland',
    countryCode: 'NL',
    currencyCode: 'EUR',
    officialLanguageCode: 'nl'
  },
  {
    countryNameLocal: 'دولة الإمارات العربيّة المتّحدة',
    countryCode: 'AE',
    currencyCode: 'AED',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'Moldova, Молдавия',
    countryCode: 'MD',
    currencyCode: 'MDL',
    officialLanguageCode: 'ro'
  },
  {
    countryNameLocal: 'The Gambia',
    countryCode: 'GM',
    currencyCode: 'GMD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'República Dominicana',
    countryCode: 'DO',
    currencyCode: 'DOP',
    officialLanguageCode: 'es'
  },
  {
    countryNameLocal: 'السودان',
    countryCode: 'SD',
    currencyCode: 'SDG',
    officialLanguageCode: 'ar'
  },
  {
    countryNameLocal: 'ປະຊາຊົນລາວ',
    countryCode: 'LA',
    currencyCode: 'LAK',
    officialLanguageCode: 'lo'
  },
  {
    countryNameLocal: 'Taiwan',
    countryCode: 'TW',
    currencyCode: 'TWD',
    officialLanguageCode: 'zh-hant'
  },
  {
    countryNameLocal: 'République du Congo',
    countryCode: 'CG',
    currencyCode: 'XAF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Česká republika',
    countryCode: 'CZ',
    currencyCode: 'CZK',
    officialLanguageCode: 'cs'
  },
  {
    countryNameLocal: 'Great Britain',
    countryCode: 'GB',
    currencyCode: 'GBP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Niger',
    countryCode: 'NE',
    currencyCode: 'XOF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Democratic Republic of the Congo',
    countryCode: 'CD',
    currencyCode: 'CDF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Commonwealth of The Bahamas',
    countryCode: 'BS',
    currencyCode: 'BSD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Pulu Kokos (Keeling)',
    countryCode: 'CC',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'République centrafricaine',
    countryCode: 'CF',
    currencyCode: 'XAF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: "Kūki 'Āirani",
    countryCode: 'CK',
    currencyCode: 'NZD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Falkland Islands',
    countryCode: 'FK',
    currencyCode: 'FKP',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Færøerne',
    countryCode: 'FO',
    currencyCode: 'DKK',
    officialLanguageCode: 'da'
  },
  {
    countryNameLocal: 'Territory of Heard Island and McDonald Islands',
    countryCode: 'HM',
    currencyCode: 'AUD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'British Indian Ocean Territory',
    countryCode: 'IO',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Umoja wa Komori',
    countryCode: 'KM',
    currencyCode: 'KMF',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'Cayman Islands',
    countryCode: 'KY',
    currencyCode: 'KYD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Aolepān Aorōkin Ṃajeḷ',
    countryCode: 'MH',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Sankattan Siha Na Islas Mariånas',
    countryCode: 'MP',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Turks and Caicos Islands',
    countryCode: 'TC',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Terres australes et antarctiques françaises',
    countryCode: 'TF',
    currencyCode: 'EUR',
    officialLanguageCode: 'fr'
  },
  {
    countryNameLocal: 'United States Minor Outlying Islands',
    countryCode: 'UM',
    currencyCode: 'USD',
    officialLanguageCode: 'en'
  },
  {
    countryNameLocal: 'Sancta Sedes',
    countryCode: 'VA',
    currencyCode: 'EUR',
    officialLanguageCode: 'la'
  },
  {
    countryNameLocal: 'Republika e Kosovës',
    countryCode: 'XK',
    currencyCode: 'EUR',
    officialLanguageCode: 'sq'
  },
  {
    countryNameLocal: 'Nederlandse Antillen',
    countryCode: 'AN',
    currencyCode: 'ANG',
    officialLanguageCode: 'nl'
  }
] as const;

/**
 * @deprecated usw explicit countries import instead.
 */
export default countries;
