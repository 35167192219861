<template>
  <template v-if="loaded">
    <ModalModule
      v-model="showModal"
      :close-icon-text="
        textDictionary['pageFooter.languageSelector.modalCloseIconText'] ??
        'Close'
      "
      :title="
        textDictionary['pageFooter.languageSelector.modalTitle'] ??
        'Region & language'
      "
      @abort="() => (showModal = false)"
    >
      <div :class="$style.modalContent">
        <h4 class="mb-4">
          <template v-if="geoInformationStore.detectedCountry">
            {{ currentCountryDictionarySplit[0] }}
            <LibCircleFlag
              v-if="
                geoInformationStore.detectedCountry &&
                geoInformationStore.showCountryFlags
              "
              class="d-inline-block"
              size="small"
              :alt="geolocationCountryDisplayName"
              :country="geoInformationStore.detectedCountry.toLowerCase()"
            />
            {{
              geolocationCountryDisplayName + currentCountryDictionarySplit[1]
            }}
          </template>
          {{
            textDictionary['pageFooter.languageSelector.otherCountry'] ??
            'Do you want to access a different region?'
          }}
        </h4>
        <div class="d-flex gap-4 flex-column">
          <div
            class="flex-grow-1 position-relative d-flex justify-content-between flex-column h-100"
          >
            <div class="fw-bold pb-2">
              {{
                textDictionary[
                  'pageFooter.languageSelector.searchRegionDropdownLabel'
                ] ?? 'Search for and select a region'
              }}
            </div>
            <Dropdown
              v-if="dropdownOverlayRef"
              v-model="selectedCountry"
              :append-to="dropdownOverlayRef"
              :panel-props="{ style: { left: 0, top: 0, right: 0 } }"
              :options="sortedCountryOptions"
              option-label="countryNameLocal"
              :filter-fields="['countryNameLocal']"
              :pt="{
                item: () => ({
                  class: 'd-flex align-items-center gap-3'
                }),
                input: () => ({
                  class: 'd-flex align-items-center gap-3'
                })
              }"
              filter
              auto-filter-focus
            >
              <template #value="slotProps">
                <template v-if="slotProps.value">
                  <LibCircleFlag
                    v-if="geoInformationStore.showCountryFlags"
                    size="small"
                    :alt="slotProps.value.countryNameLocal"
                    :country="
                      slotProps.value.countryCode?.toLowerCase() ?? 'de'
                    "
                  />
                  {{ slotProps.value.countryNameLocal }}
                </template>
                <template v-else>{{ slotProps.placeholder }}</template>
              </template>
              <template #option="slotProps">
                <LibCircleFlag
                  v-if="geoInformationStore.showCountryFlags"
                  size="small"
                  :alt="slotProps.option.countryNameLocal"
                  :country="slotProps.option.countryCode?.toLowerCase() ?? 'de'"
                />
                {{ slotProps.option.countryNameLocal }}
              </template>
            </Dropdown>
            <div
              class="position-relative"
              style="transform: translate(0, 0); z-index: 10"
            >
              <div
                ref="dropdownOverlayRef"
                class="position-absolute"
                style="top: 0; left: 0; right: 0"
              />
            </div>
          </div>
          <div class="flex-grow-1 d-flex justify-content-between flex-column">
            <div class="fw-bold">
              {{
                textDictionary[
                  'pageFooter.languageSelector.chooseLanguageLabel'
                ] ?? 'Choose your language'
              }}
            </div>
            <div class="flex-grow-1 d-flex align-items-center">
              <FormKit
                v-model="selectedLocale"
                type="radio"
                :classes="{
                  outer: $style.localeSelector,
                  fieldset: $style.fieldSetNoBorder
                }"
                :options="
                  availableLanguages.map((language) => ({
                    label: language.label,
                    value: language.locale
                  }))
                "
              />
            </div>
          </div>
          <div
            class="flex-grow-1 d-flex align-items-center justify-content-center mt-4"
          >
            <LinkElement
              :disabled="!selectedRegionLink"
              :link="{ uri: selectedRegionLink }"
              style-type="primary"
              @click="setRegionLocaleCookie"
            >
              {{
                textDictionary['pageFooter.languageSelector.chooseRegion'] ??
                'Choose region'
              }}
            </LinkElement>
          </div>
        </div>
      </div>
    </ModalModule>
  </template>
</template>

<script setup lang="ts">
import Dropdown from 'primevue/dropdown';
import { LinkElement, ModalModule } from '@hypercodestudio/basler-components';
import { getCurrentLanguages } from '~/stores/geoinformationStore/helper/getCurrentLanguages';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';
import { getLocaleCountryLabel } from '~/stores/geoinformationStore/helper/getLocaleCountryLabel';
import { countries } from '~/utils/countries';
import { unique } from '~/utils/unique';
import type { RegionSelectorResponseModel } from '~/server/api/contentful/regionSelector.get';

const route = useRoute();
const { $localeSlugMap, $textDictionary, $modals } = useNuxtApp();
const logger = useLogger();

const showModal = $modals.regionSelectorOpen;
const dropdownOverlayRef = ref<HTMLElement>();
const geoInformationStore = useGeoinformationStore();
const geolocationCountryDisplayName = computed(() =>
  getLocaleCountryLabel(
    geoInformationStore.detectedCountry,
    geoInformationStore.clientLocale
  )
);
const sortedCountryOptions = computed(() =>
  unique(
    countries
      .map(({ countryNameLocal, countryCode }) => ({
        countryNameLocal:
          getLocaleCountryLabel(
            countryCode,
            geoInformationStore.clientLocale
          ) ?? countryNameLocal,
        countryCode
      }))
      .sort((a, b) =>
        a.countryNameLocal.localeCompare(
          b.countryNameLocal,
          geoInformationStore.clientLocale
        )
      ),
    (country) => country.countryCode
  )
);
const selectedCountry = ref<{
  countryNameLocal: string;
  countryCode: string;
}>(sortedCountryOptions.value[0]);
const selectedLocale = ref<string>();
const textDictionary = ref<Record<string, string>>($textDictionary.value);
const loaded = ref(false);

// XXX: we should only load data once the region
//  selector is opened the first time.
watch(
  () => geoInformationStore.initiated,
  async (nextValue) => {
    // XXX: if the geoInformationStore fails to initiate, the region
    //  selector won't work. We should display it in the current
    //  locale as fallback.
    if (nextValue && !loaded.value) {
      try {
        const { dictionary } = await $fetch<RegionSelectorResponseModel>(
          '/api/contentful/regionSelector',
          {
            query: { locale: geoInformationStore.clientLocale }
          }
        );
        textDictionary.value = dictionary;
      } catch (e) {
        logger.error('failed to load dictionary in client language', e);
      } finally {
        loaded.value = true;
      }
    }
  }
);

const currentCountryDictionarySplit = computed(() =>
  (
    textDictionary.value['pageFooter.languageSelector.currentCountry'] ??
    'You are located in {currentCountry}.'
  ).split('{currentCountry}')
);
const availableLanguages = ref<
  { locale: string; label: string; link: string }[]
>([]);

watch(availableLanguages, () => {
  selectedLocale.value = availableLanguages.value[0]?.locale;
});

watch(
  () => route.hash,
  () => {
    showModal.value = route.hash === '#regionselector';
  },
  { immediate: true }
);

const router = useRouter();

watch(showModal, (newValue, oldValue) => {
  // remove hash if the dialog is closed
  if (!newValue && oldValue) {
    router.push({
      hash: undefined
    });
  }
});

const selectedRegionLink = computed<string | undefined>(() => {
  if (isEmpty(availableLanguages.value)) {
    return;
  }

  const link = availableLanguages.value.find(
    (lang) => lang.locale === selectedLocale.value
  )?.link;

  if (!link) {
    logger.error(
      'failed to get link for selected locale',
      toRaw(availableLanguages.value)
    );
  }

  return link;
});

const setRegionLocaleCookie = () => {
  geoInformationStore.updatePreferredSettings({
    locale: selectedLocale.value,
    country: selectedCountry.value?.countryCode
  });
};

watch(
  [selectedCountry, $localeSlugMap],
  () => {
    availableLanguages.value = getCurrentLanguages(
      selectedCountry.value?.countryCode,
      geoInformationStore.clientLocale,
      $localeSlugMap.value,
      route.path
    );
  },
  { immediate: true }
);

watch(
  [
    () => geoInformationStore.preferredCountry,
    () => geoInformationStore.detectedCountry
  ],
  () => {
    const preferredCountry =
      sortedCountryOptions.value.find(
        (country) =>
          country.countryCode === geoInformationStore.preferredCountry
      ) ??
      sortedCountryOptions.value.find(
        (country) => country.countryCode === geoInformationStore.detectedCountry
      );

    if (preferredCountry) {
      selectedCountry.value = preferredCountry;
    }
  }
);
</script>

<style module>
.localeSelector li {
  white-space: nowrap;
}

.localeSelector ul {
  grid-template-columns: none !important;
}

.localeSelector {
  margin: 0;
}

.fieldSetNoBorder {
  border: none !important;
}

.modalContent * {
  font-family: Inter var;
}
</style>

<style>
/* overwrite the primevue theme styles*/
.p-dropdown:not(.p-disabled).p-focus,
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 1px var(--fk-color-primary);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: var(--fk-color-primary);
}

.p-dropdown-panel {
  width: 100%;
  min-width: auto !important;
  max-width: 100vw;
}
</style>
