import type { DownloadEntryModel } from '~/types/model/algolia/DownloadEntryModel';

export const ALGOLIA_DOCUMENT_WEBAPP_LOCALE_TO_LANGUAGE_MAP: Record<
  string,
  string
> = {
  'ko-kr': 'KO',
  'zh-cn': 'CN',
  'de-de': 'DE',
  en: 'EN',
  'ja-jp': 'JP',
  'zh-tw': 'ZH-TW',
  'ru-ru': 'RU'
};

export function buildDocumentDownloadLink(
  document: DownloadEntryModel,
  locale: string
) {
  const algoliaDocumentLanguage =
    ALGOLIA_DOCUMENT_WEBAPP_LOCALE_TO_LANGUAGE_MAP[locale];

  const linkForLocale = document.links
    .filter((meta) => meta.language === algoliaDocumentLanguage && meta.url)
    .at(0)?.url;

  if (!linkForLocale) {
    return null;
  }

  const {
    public: { algoliaDocumentDownloadHost }
  } = useRuntimeConfig();

  return algoliaDocumentDownloadHost + linkForLocale;
}
