import type { Ref } from 'vue';
import type { ColorMode } from '@hypercodestudio/basler-components/dist/pages/Page.vue';
import type { LOCALE_CODE } from '~/lib/ContentfulService';

export default defineNuxtPlugin(() => {
  const { $requestedLocale } = useNuxtApp();
  const url = useRequestURL();

  const colorMode: Ref<ColorMode | null> = useState<ColorMode | null>(
    'colorMode',
    () =>
      url.pathname.startsWith(`/${$requestedLocale.value}/shop`)
        ? 'light'
        : null
  );
  /**
   * Should contain a slug for every available page in the given language code.
   */
  const localeSlugMap = useState<Partial<Record<LOCALE_CODE, string>>>(
    'localeSlugMap',
    () => ({})
  );

  return {
    provide: {
      colorMode,
      localeSlugMap
    }
  };
});
