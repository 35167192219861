import { getLocaleLanguageLabel } from '~/stores/geoinformationStore/helper/getLocaleLanguageLabel';
import type { LOCALE_CODE } from '~/lib/ContentfulService';
import { convertContentfulToWebappLocaleCode } from '~/utils/convertContentfulToWebappLocaleCode';
import { buildUrlString } from '~/utils/buildUrlString';
import { countryLocaleMapping } from '~/utils/countriesWithLocaleMapping';

const LOCALES_WITH_EXTERNAL_SITE = ['it', 'fr'];

type LanguageEntry = {
  /**
   * The locale. NOT the contentful locale.
   *
   * @example "zh-tw"
   */
  locale: string;
  /**
   * A readable text of the language in the client locale language
   *
   * @example "Chinese"
   */
  label: string;
  link: string;
};

/**
 * Creates a list of available languages and according links.
 *
 * @param selectedCountryCode The selected country code in (e.g. "TW").
 * @param currentLocale The current active, lowercase locale (not the contentful locale).
 * @param localeSlugMap The locale slug map to use.
 * @param currentPath The current path.
 */
export function getCurrentLanguages(
  selectedCountryCode: string | undefined,
  currentLocale: string | undefined | null,
  localeSlugMap: Partial<Record<LOCALE_CODE, string>>,
  currentPath: string
): LanguageEntry[] {
  if (!selectedCountryCode) {
    return [];
  }

  const startOfPdpRoute = `/${currentLocale}/`;
  const isPdp = currentPath.startsWith(startOfPdpRoute + 'shop/');
  const pdpSlug = currentPath.slice(startOfPdpRoute.length);
  const defaultSlugMapping: [string, string][] = Object.entries(
    localeSlugMap
  ).map(([locale, slug]) => [
    convertContentfulToWebappLocaleCode(locale),
    slug
  ]);
  const isExternal = LOCALES_WITH_EXTERNAL_SITE.includes(
    selectedCountryCode.toLowerCase()
  );

  return countryLocaleMapping
    .filter((localeCountryMapping) =>
      localeCountryMapping.identifier.includes(selectedCountryCode)
    )
    .map((localeCountryMapping) => {
      /** @example "zh-tw" **/
      const localeFromCountriesWithLocaleMapping =
        localeCountryMapping.locale?.toLowerCase();
      const pdpArray = [localeFromCountriesWithLocaleMapping, pdpSlug];
      const pdpSlugMappings = Object.entries([pdpArray]).map(
        ([_code, slug]) => slug
      );
      const availableLocaleCodesFromSlugMap = isPdp
        ? (pdpSlugMappings as [string, string | null][])
        : defaultSlugMapping;
      const matchedItemFromSlugMap = availableLocaleCodesFromSlugMap.find(
        ([code]) => code?.toLowerCase() === localeFromCountriesWithLocaleMapping
      );
      const resultLocale = isExternal
        ? selectedCountryCode
        : matchedItemFromSlugMap?.[0] ??
          localeFromCountriesWithLocaleMapping ??
          'en';
      const resultSlug = matchedItemFromSlugMap?.[1] ?? '/';
      const resultLink = buildUrlString(
        resultLocale,
        isExternal ? '/' : resultSlug,
        undefined,
        undefined,
        true
      );

      return {
        locale: resultLocale,
        label:
          getLocaleLanguageLabel(resultLocale, currentLocale) ?? resultLocale,
        link: resultLink
      };
    });
}
