export function createShippingTimeText({
  showShippingTime,
  shippingTime,
  fallbackLabel,
  labelTemplateSingle,
  labelTemplateMulti
}: {
  showShippingTime: boolean;
  shippingTime: number;
  fallbackLabel?: string;
  labelTemplateSingle?: string;
  labelTemplateMulti?: string;
}): string | undefined {
  if (!showShippingTime) {
    return fallbackLabel?.replace(/(<([^>]+)>)/gi, '');
  }
  const weeks = Math.floor(shippingTime / 7);
  const template = weeks < 1 ? labelTemplateSingle : labelTemplateMulti;

  return template
    ?.replace(/(<([^>]+)>)/gi, '')
    ?.replace('{from}', weeks.toString())
    ?.replace('{to}', (weeks + (shippingTime > 90 ? 2 : 1))?.toString());
}
