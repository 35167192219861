import type { RouteMiddleware } from 'nuxt/app';
import { Tracer } from '~/lib/Tracing';
import { createNewUrlFromRedirectAndCurrentUrl } from '~/utils/createNewUrlFromRedirectAndCurrentUrl';
import { getPlainUrl } from '~/utils/getPlainUrl';
import { DEFAULT_FETCH_OPTIONS } from '~/utils/defaultFetchOptions';
import { enhancedNavigateTo } from '~/utils/enhancedNavigateTo';
import { useGeoinformationStore } from '~/stores/geoinformationStore/geoinformationStore';

const pageRedirectMiddleware: RouteMiddleware = async (to) => {
  const tracer = new Tracer('middleware');
  const redirectCache = useState<
    Record<string, { statusCode?: number; url?: URL }>
  >('redirectCache', () => ({}));

  await tracer.withSpan('pageRedirect', {}, async () => {
    // Global server middleware handles serverside redirects.
    if (process.server) {
      return;
    }
    // Ignore initial loading of a page.
    const nuxtApp = useNuxtApp();
    if (
      process.client &&
      nuxtApp.isHydrating &&
      nuxtApp.payload.serverRendered
    ) {
      return;
    }
    const url = useRequestURL();
    const cacheKey = getPlainUrl(url);
    const redirect =
      redirectCache.value[cacheKey] ?? (await getRedirectUrl(to.path, url));

    redirectCache.value[cacheKey] = redirect;

    if (!redirect.url) {
      return;
    }

    return enhancedNavigateTo(
      {
        query: Object.fromEntries(redirect.url.searchParams.entries()),
        force: true,
        path: redirect.url.href
      },
      {
        redirectCode: redirect.statusCode,
        external: url.origin !== redirect.url.origin
      }
    );
  });
};

async function getRedirectUrl(path: string, currentUrl: URL) {
  const pageRedirect = await $fetch('/api/contentful/redirect', {
    ...DEFAULT_FETCH_OPTIONS,
    query: {
      path
    }
  });

  if (!pageRedirect?.redirect) {
    return {};
  }

  const store = useGeoinformationStore();

  return {
    statusCode: pageRedirect.redirect.statusCode,
    url: createNewUrlFromRedirectAndCurrentUrl(
      pageRedirect.redirect,
      currentUrl,
      () => store.ensureRedirectLocale()
    )
  };
}

export default defineNuxtRouteMiddleware(pageRedirectMiddleware);
