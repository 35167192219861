import type { Entry } from 'contentful';
import type { HyperlinkInterface } from '@hypercodestudio/basler-components/dist/components/helpers/HyperLink.vue';
import { isEntryOfContentType } from './guards/isEntryOfContentType';
import { isDefined } from './guards/isDefined';
import type { PageFragment } from './guards/isContentPageFragment';
import { buildDownloadUrlString } from '~/utils/buildDownloadUrlString';
import { isContentPage } from '~/utils/guards/isContentPage';
import { buildUrlString } from '~/utils/buildUrlString';
import type {
  LinkFragment,
  MetadataProviderFragment,
  NavigationItemFragment
} from '~/lib/ContentfulGraphqlService';
import { isLinkFragment } from '~/utils/guards/isLinkFragment';
import { isNavigationItemFragment } from '~/utils/guards/isNavigationItemFragment';
import type { INavigationItem, ILink } from '~/lib/ContentfulService';
import { isMetaDataProviderFragment } from '~/utils/guards/isMetaDataProviderFragment';

export type BuildLinkInterfaceParams =
  | null
  | Entry<unknown>
  | string
  | LinkFragment
  | INavigationItem
  | PageFragment
  | NavigationItemFragment
  | MetadataProviderFragment;

/**
 * This method creates a link to an entry.
 * A link is only created if a ContentPage (@see {@link isContentPage}) is
 * given or a link is given.
 *
 * @param element The element to use.
 * @param locale The locale to use.
 * @param withAbsolutePath If the protocol and host should be prepended.
 * @param logger The logger to use.
 */
export function buildLinkInterface(
  element?: BuildLinkInterfaceParams,
  locale = '',
  withAbsolutePath = false,
  logger = console
): HyperlinkInterface {
  if (isNavigationItemFragment(element)) {
    if (
      element.externalLink &&
      element.externalLink.externalUrl &&
      !element.externalLink.internalPage
    ) {
      return buildGraphqlLink(element.externalLink, locale);
    }
    return buildGraphqlInternalLink(element, locale, withAbsolutePath);
  }

  if (isLinkFragment(element)) {
    if (element.externalUrl && !element.internalPage) {
      return buildGraphqlLink(element, locale);
    }

    return buildGraphqlInternalLink(element, locale, withAbsolutePath);
  }

  if (isMetaDataProviderFragment(element)) {
    return {
      uri: buildUrlString(
        locale,
        element.metadata?.slug ?? undefined,
        undefined,
        undefined,
        withAbsolutePath
      ),
      target: '_self',
      external: false
    };
  }

  if (isEntryOfContentType(element, 'navigationItem')) {
    return element.fields.externalLink && !element.fields.internalPage
      ? buildLinkInterface(
          element.fields.externalLink,
          locale,
          withAbsolutePath
        )
      : {
          uri: buildUrlString(
            element.sys.locale,
            element.fields.internalPage?.fields?.metadata?.fields?.slug ?? '',
            undefined,
            undefined,
            withAbsolutePath
          ),
          target: element.fields.linkTarget,
          external: element.fields.linkTarget === '_blank'
        };
  }

  if (isEntryOfContentType(element, 'link')) {
    if (isEntryOfContentType(element.fields.internalPage, 'download')) {
      return buildLinkInterface(
        element.fields.internalPage,
        locale,
        withAbsolutePath
      );
    }

    if (isDefined(element.fields.asset)) {
      return {
        uri: element.fields.asset?.fields?.file?.url ?? '',
        target: element.fields.linkTarget,
        external: element.fields.linkTarget === '_blank'
      };
    }

    return {
      uri: linkEntryToUrl(element, locale, withAbsolutePath) ?? '',
      target: element.fields.linkTarget,
      external: element.fields.linkTarget === '_blank'
    };
  }

  if (isEntryOfContentType(element, 'download')) {
    return {
      uri: buildDownloadUrlString(element.sys.locale, element.fields.slug),
      target: '_blank',
      external: true
    };
  }

  if (isContentPage(element)) {
    if (element.fields.metadata?.fields?.slug) {
      return {
        uri: buildUrlString(
          element.sys.locale,
          element.fields.metadata.fields.slug,
          undefined,
          undefined,
          withAbsolutePath
        ),
        target: '_self',
        external: false
      };
    }

    logger.warn(
      'buildLinkInterface',
      `referred content page ${element.sys.id} has no slug - can not build link`
    );
  }

  if (isEntryOfContentType(element, 'tag')) {
    if (element.fields?.slug) {
      return {
        uri: buildUrlString(
          element.sys.locale,
          element.fields.slug,
          undefined,
          undefined,
          withAbsolutePath
        ),
        target: '_self',
        external: false
      };
    }

    logger.warn(
      `referred Tag ${element.sys.id} has no slug - can not build link`
    );
  }

  if (typeof element === 'string') {
    return {
      uri: element,
      target: '_self',
      external: false
    };
  }

  return {
    uri: '',
    target: '_self',
    external: false
  };
}

function buildGraphqlLink(
  link: LinkFragment,
  locale: string
): HyperlinkInterface {
  let externalUrl = link?.externalUrl;
  const isShopUrl = externalUrl?.startsWith('/shop/');

  if (isShopUrl && externalUrl) {
    externalUrl = buildUrlString(locale, externalUrl);
  }

  return {
    uri: externalUrl ?? undefined,
    target: link.linkTarget ?? undefined,
    external: link.linkTarget === '_blank'
  };
}

function buildGraphqlInternalLink(
  element: NavigationItemFragment | LinkFragment,
  locale: string,
  withAbsolutePath: boolean
): HyperlinkInterface {
  const link = element.internalPage
    ? element
    : isNavigationItemFragment(element)
    ? element.externalLink
    : null;

  if (link?.internalPage?.__typename === 'Download') {
    return {
      uri: buildDownloadUrlString(locale, link?.internalPage.slug ?? ''),
      target: '_blank',
      external: true
    };
  }

  const slug = link?.internalPage?.metadata?.slug ?? '';
  const isLink = isLinkFragment(link);
  const query = (isLink && link.queryParameter) || undefined;
  const anchor = (isLink && link.internalAnchor?.id) || undefined;

  const uri = buildUrlString(locale, slug, query, anchor, withAbsolutePath);

  return {
    uri,
    target: link?.linkTarget ?? undefined,
    external: link?.linkTarget === '_blank'
  };
}

function linkEntryToUrl(
  link: ILink,
  locale: string,
  withAbsolutePath = false
): string | undefined {
  const pageOrDownload = link?.fields.internalPage;

  if (!isDefined(pageOrDownload)) {
    const isShopUrl = link?.fields.externalUrl?.startsWith('/shop/');

    if (isShopUrl) {
      return buildUrlString(locale, link?.fields.externalUrl);
    }

    return link?.fields.externalUrl;
  }

  if (isEntryOfContentType(pageOrDownload, 'download')) {
    return buildDownloadUrlString(locale, pageOrDownload.fields.slug);
  }

  const slug = pageOrDownload.fields?.metadata.fields?.slug;

  if (!isDefined(slug)) {
    return;
  }

  const query = link?.fields?.queryParameter;
  const anchorFields = link?.fields.internalAnchor?.fields;
  const anchor =
    anchorFields && 'id' in anchorFields ? anchorFields.id : undefined;

  return buildUrlString(locale, slug, query, anchor, withAbsolutePath);
}
