import type { LOCALE_CODE } from '~/lib/ContentfulService';

export default defineNuxtPlugin({
  name: 'locale',
  enforce: 'pre',
  async setup() {
    const requestedLocale = useRouteParamLocale();
    const locale = useState<Lowercase<string>>('locale');
    const resolvedLocale = useState<LOCALE_CODE | undefined>(
      'resolvedLocale',
      () => undefined
    );
    const availableLocales = useState<string[]>('locales', () => []);

    if (!resolvedLocale.value) {
      try {
        const fetchedLocale = await $fetch<LOCALE_CODE | undefined>(
          '/api/contentful/resolveLocale',
          {
            ...DEFAULT_FETCH_OPTIONS,
            query: { locale: requestedLocale.value }
          }
        );

        resolvedLocale.value = fetchedLocale;
        // XXX: combine in function with server side
        // https://gcp.baslerweb.com/jira/browse/WEB2-2275
        // Use en as fallback locale
        locale.value = (fetchedLocale?.toLowerCase() ??
          'en') as Lowercase<string>;
      } catch (_e) {
        // XXX: handle error?
        locale.value = 'en';
      }
    }

    return {
      provide: {
        /**
         * The resolved contentful locale.
         * @example "en-US"
         */
        resolvedLocale,
        /**
         * The locale in lowercase with fallback.
         * @example "en-us"
         */
        locale,
        /**
         * The **mapped** requested locale!
         * @example "zh-tw"
         */
        requestedLocale,
        /**
         * A list of all available locale *codes*
         * @example ["de-DE", "en", "en-US"]
         */
        availableLocales
      }
    };
  }
});
