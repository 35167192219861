<template>
  <LibPageContainer v-if="resolvedPage" :color-mode="colorTheme">
    <div v-if="errorPageHasLoadingError || !page" :class="$style.error">
      Something went wrong
      <pre v-if="isPreviewEnabled">{{ error }}</pre>
      <pre v-if="isPreviewEnabled">{{ String(errorPageHasLoadingError) }}</pre>
    </div>
    <MappedComponent v-else :item="resolvedPage" />
  </LibPageContainer>
</template>

<script setup lang="ts">
import type { ColorMode } from '@hypercodestudio/basler-components/dist/pages/Page.vue';
import type { NuxtError } from 'nuxt/app';
import { setResponseStatus } from 'nuxt/app';
import type { EntryWithLinkData, IContentPage } from './lib/ContentfulService';
import type { IPage } from './utils/guards/isContentPage';

interface Props {
  error: NuxtError;
}

const props = defineProps<Props>();

const locale = useLocale();
const { $globalPageSettings } = useNuxtApp();

const {
  public: { isPreviewEnabled }
} = useRuntimeConfig();

const { data: page, error: errorPageHasLoadingError } =
  await useAsyncData<EntryWithLinkData<IPage> | null>(
    (nuxtApp) => {
      const requestEvent = useRequestEvent(nuxtApp);

      if (requestEvent) {
        setResponseStatus(
          requestEvent,
          props.error.statusCode,
          props.error.statusMessage
        );
      }

      return $fetch<EntryWithLinkData<IContentPage>>(
        '/api/contentful/errorPage',
        {
          ...DEFAULT_FETCH_OPTIONS,
          query: { locale: locale.value, statusCode: props.error.statusCode }
        }
      );
    },
    {
      watch: [() => props.error, locale]
    }
  );

const resolvedPage = computed(() => {
  if (!page.value) {
    return null;
  }

  return resolveEntry(page.value);
});
const colorTheme = computed<ColorMode>(
  () => resolvedPage.value?.fields?.metadata?.fields?.pageTheme ?? 'dark'
);

if (resolvedPage.value) {
  const companyName = $globalPageSettings.value?.companyName ?? '';
  useMetadata(resolvedPage.value, companyName);
}
</script>

<style module>
.error {
  color: #fff;
}
</style>
