import type { DownloadEntryModel } from '~/types/model/algolia/DownloadEntryModel';

export function buildDocumentDownloadTitle(
  document: DownloadEntryModel,
  locale: string
) {
  const algoliaDocumentLanguage =
    ALGOLIA_DOCUMENT_WEBAPP_LOCALE_TO_LANGUAGE_MAP[locale];

  const nameForLocale =
    document.meta
      .filter((meta) => meta.language === algoliaDocumentLanguage && meta.name)
      .at(0)?.name ?? document.name;

  return `${nameForLocale} (${document.format}, ${document.readableSize})`;
}
