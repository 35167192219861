export function isEmpty(input: unknown): boolean {
  if (input == null) {
    return true;
  }
  if (typeof input === 'boolean') {
    return false;
  }
  if (typeof input === 'number') {
    return isNaN(input);
  }
  if (typeof input === 'object') {
    return Object.keys(input).length === 0;
  }
  if (typeof input === 'string') {
    return input.trim().length === 0;
  }

  return true;
}
